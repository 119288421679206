<template lang="pug">
.register(v-if="!loading")

  .page.slim(v-show="step == 'account'")
    h1 Sua conta

    AccountForm(ref="accountForm", :data="accountData")

    Dialog(v-model:open="showUsedEmailDialog", title="E-mail já cadastrado")
      p Já existe uma conta com o e-mail informado.

      p Por favor, acesse a sua conta para finalizar a sua assinatura.

      template(v-slot:footer="{ close }")
        .actions(style="text-align: right")
          Button(label="Voltar", plain, @click="close")
          Button(label="Acessar conta", @click="redirectToSignIn")

  .page.slim(v-show="step == 'shipping-address'")
    h1 Endereço de entrega

    AddressForm(ref="shippingAddressForm", :data="shippingAddressInfo")

  .page(v-if="step == 'checkout'")
    h1 Sua assinatura

    SubscriptionCheckoutForm(
      ref="checkoutForm",
      :data="checkoutData",
      :customerCPF="accountData.cpf",
      :shippingAddressInfo="shippingAddressInfo")

  .page(v-show="step == 'profile'")
    h1 Perfil de beleza

    Questionnaire(
      ref="questionnaireForm",
      v-show="step == 'profile' && !showProfileWarning",
      :questionnaire="questionnaire",
      :answers="profileData.answers",
      :current="currentQuestion")

    QuestionnaireWarning(v-show="showProfileWarning", :remainingProducts="numberOfProductTypesStillNeeded")

  .actions(v-if="!showProfileWarning")
    IconButton.back(v-show="showPrevious", :icon="[ 'fas', 'chevron-left' ]", to="", @click.stop.prevent="navigateTo(previous)")

    Button.large(v-show="showSignUpButton", rounded, label="Próximo", @click="signUp", :disabled="!accountData.acceptsTermsOfUseAndPrivacyPolicy", :loading="isSigningUp")

    Button.large(v-show="showCreateShippingAddressButton", rounded, label="Próximo", @click="createShippingAddress", :loading="isCreatingShippingAddress")

    Button.large(v-show="showSubscribeButton", rounded, label="Assinar", @click="subscribe", :loading="isProcessingSubscription")

    Button.large(v-show="showSaveProfileButton", rounded, label="Salvar", @click="saveProfile", :loading="isSavingProfile")

    Progress(v-if="step == 'profile' && !showSaveProfileButton", :progress="questionnaireProgress")

    IconButton.next(v-show="showNext", :icon="[ 'fas', 'chevron-right' ]", to="", @click.stop.prevent="validateCurrentStep(next)")

  .actions(v-if="showProfileWarning")
    Button.large(rounded, label="Voltar", @click="showProfileWarning = false")
</template>
<script setup lang="ts">
import type { FetchError } from 'ofetch'
import type { WatchStopHandle } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import type { TrackEventOptions } from '@gtm-support/vue-gtm'

import type { SubscriptionCheckoutFormModel } from '~/components/subscription/SubscriptionCheckoutForm.vue'

import type { Answer } from '~/services/profile/profileService'
import type { Questionnaire } from '~/services/profile/questionnaireService'
import type { Subscription } from '~/services/subscription/subscriptionService'
import type { VindiTokenizationResponse } from '~/services/vindi/vindiService'

import { v4 as uuidv4 } from 'uuid'

import PancakeService from '~/services/pancake/pancakeService'

import { useGtm } from '@gtm-support/vue-gtm'
import { useReCaptcha } from 'vue-recaptcha-v3'

import AccountForm from '~/components/account/AccountForm.vue'
import AddressForm from '~/components/address/AddressForm.vue'
import QuestionnaireComponent from '~/components/profile/questionnaire/Questionnaire.vue'
import SubscriptionCheckoutForm from '~/components/subscription/SubscriptionCheckoutForm.vue'

import ProfileService from '~/services/profile/profileService'
import QuestionnaireService from '~/services/profile/questionnaireService'
import { planAliasToId, planBasePrice } from '~/services/subscription/subscriptionService'
import FacebookPixelService from '~/services/facebook/facebookPixelService'
import VindiService from '~/services/vindi/vindiService'

import { localDateToISODate } from '~/scripts/date/date'

import { useAuth } from '~/store/auth'
import shippingAddressService from '~/services/shippingAddress/shippingAddressService'
import { useActiveSubscription } from '~/store/subscription/subscription'
import { trackSubscriptionConversion } from '~/composables/affiliate/affiliateTracking'

definePageMeta({
  key: "subscription-register"
})

enum RegisterStep {
  Account = "account",
  ShippingAddress = "shipping-address",
  Checkout = "checkout",
  Profile = "profile"
}

const stepToAlias: Record<string, string> = {
  "account": "conta",
  "shipping-address": "endereco-de-entrega",
  "checkout": "pagamento",
  "profile": "perfil-de-beleza",
}

const aliasToStep: Record<string, RegisterStep> = {
  "conta": RegisterStep.Account,
  "endereco-de-entrega": RegisterStep.ShippingAddress,
  "pagamento": RegisterStep.Checkout,
  "perfil-de-beleza": RegisterStep.Profile,
}

// TODO: Validate plan is selected
const steps = ref([
  RegisterStep.Profile,
  RegisterStep.Account,
  RegisterStep.ShippingAddress,
  RegisterStep.Checkout,
])

const config = useConfig()
const auth = useAuth()
const activeSubscription = useActiveSubscription()
const route = useRoute()
const router = useRouter()
const gtm = useGtm()
const recaptcha = useReCaptcha();
const notifications = useNotifications()
const posthog = usePostHog()

const billingPlatformId = config.billingPlatformId
const cardVerificationEnabled = config.vindi.cardVerificationEnabled

const loading = ref(true)
const isProcessingSubscription = ref(false)
const isSigningUp = ref(false)
const isCreatingShippingAddress = ref(false)
const isSavingProfile = ref(false)

const step = ref(steps.value[1])
const showProfileWarning = ref(false)

const accountForm = ref<InstanceType<typeof AccountForm> | null>()
const shippingAddressForm = ref<InstanceType<typeof AddressForm> | null>()
const checkoutForm = ref<InstanceType<typeof SubscriptionCheckoutForm> | null>()
const questionnaireForm = ref<InstanceType<typeof QuestionnaireComponent>>()
const currentQuestion = ref(1)
const showUsedEmailDialog = ref(false)

const couponCodes = ref<string[]>()

const questionnaire = ref<Questionnaire>()

const transactionId = uuidv4()
const customerId = ref<number | null>(null)
const shippingAddressId = ref<number | null>(null)
const accountData = reactive({
  firstName: "",
  lastName: "",
  birthdate: "",
  cpf: "",
  phoneNumber: "",
  email: "",
  password: "",
  passwordConfirmation: "",
  acceptsTermsOfUseAndPrivacyPolicy: false,
  acceptsPromotionalEmails: false
})

const shippingAddressInfo = reactive({
  street: "",
  complement: "",
  number: "",
  district: "",
  city: "",
  state: "",
  postalCode: ""
})

const checkoutData = reactive<SubscriptionCheckoutFormModel>({
  plan: null,
  creditCardInformation: {
    cardIssuer: "",
    cardNumber: "",
    cardExpiration: "",
    cardVerificationCode: "",
    cardHolderName: "",
    installments: 1
  },
  discountCoupon: null,
  giftCoupon: null,
  experiments: [],
  promotions: [],
  subscriptionGiftId: null,
})

const profileData = reactive({
  questionnaireId: 1,
  answers: [] as Answer[]
})

const forceMonthlyCharges = computed(() => {
  const monthlyChargesExperiment = posthog?.getFeatureFlag('monthly-charges-experiment')

  if (monthlyChargesExperiment == 'force-monthly-charges') {
    return true
  } else {
    return false
  }
})

const questionnaireProgress = computed(() => {
  return currentQuestion.value / (questionnaire.value?.questions.length ?? 1)
})

const numberOfProductTypesStillNeeded = computed(() => {
  return questionnaireForm.value?.numberOfProductTypesStillNeeded ?? 0
})

const firstProductTypeStep = computed(() =>
  (questionnaire.value?.questions.findIndex((question) => {
    return question.id == "desired_makeup_product" || question.id == "desired_skincare_product" || question.id == "desired_hair_product"
  }) ?? 13) + 1)

const showPrevious = computed(() => {
  const accountStep = steps.value.indexOf(RegisterStep.Account)
  const stepIndex = steps.value.indexOf(step.value)

  return stepIndex <= accountStep && ((step.value != RegisterStep.Profile && stepIndex > 0) || (step.value == RegisterStep.Profile && currentQuestion.value > 1))
})

const showNext = computed(() => {
  const stepIndex = steps.value.indexOf(step.value)

  return step.value == RegisterStep.Profile &&
    (
      (stepIndex < steps.value.length - 1) ||
      (currentQuestion.value < (questionnaire.value?.questions.length ?? 0))
    )
})

const previous = computed(() => {
  const stepIndex = steps.value.indexOf(step.value)

  var previousStep
  if (step.value == RegisterStep.Profile && currentQuestion.value > 1) {
    previousStep = RegisterStep.Profile
  } else if (stepIndex <= 1) {
    previousStep = steps.value[0]
  } else if (stepIndex >= steps.value.length - 1) {
    previousStep = steps.value[steps.value.length - 2]
  } else {
    previousStep = steps.value[stepIndex - 1]
  }

  const previousQuestion = previousStep == RegisterStep.Profile ?
    (step.value == RegisterStep.Profile ? currentQuestion.value - 1 : (questionnaire.value?.questions.length ?? 1)) :
    undefined

  const previousStepAlias = stepToAlias[previousStep]

  if (previousQuestion) {
    return { name: route.name!, params: { step: previousStepAlias, currentQuestion: previousQuestion }, query: route.query }
  } else {
    return { name: route.name!, params: { step: previousStepAlias }, query: route.query }
  }
})

const next = computed<RouteLocationRaw>(() => {
  const stepIndex = steps.value.indexOf(step.value)

  var nextStep
  if (step.value == RegisterStep.Profile && currentQuestion.value < (questionnaire.value?.questions.length ?? 0)) {
    nextStep = RegisterStep.Profile
  } else if (stepIndex < 0) {
    nextStep = steps.value[0]
  } else if (stepIndex >= steps.value.length - 1) {
    nextStep = steps.value[steps.value.length - 1]
  } else {
    nextStep = steps.value[stepIndex + 1]
  }

  const nextQuestion =
    nextStep == RegisterStep.Profile ?
      (step.value == RegisterStep.Profile ? currentQuestion.value + 1 : 1) :
      undefined

  const nextStepAlias = stepToAlias[nextStep]

  if (nextQuestion) {
    return { name: route.name!, params: { step: nextStepAlias, currentQuestion: nextQuestion }, query: route.query } as RouteLocationRaw
  } else {
    return { name: route.name!, params: { step: nextStepAlias }, query: route.query } as RouteLocationRaw
  }
})

const showSignUpButton = computed(() => step.value == RegisterStep.Account)
const showCreateShippingAddressButton = computed(() => step.value == RegisterStep.ShippingAddress)
const showSubscribeButton = computed(() => step.value == RegisterStep.Checkout)
const showSaveProfileButton = computed(() => {
  const profileStepIndex = steps.value.indexOf(RegisterStep.Profile)
  const checkoutStepIndex = steps.value.indexOf(RegisterStep.Checkout)

  return profileStepIndex > checkoutStepIndex && currentQuestion.value >= (questionnaire.value?.questions.length ?? 0)
})

const validateCurrentStep = async (to: RouteLocationRaw) => {
  if (step.value == RegisterStep.Profile) {
    if (numberOfProductTypesStillNeeded.value > 0) {
      showProfileWarning.value = true
    } else {
      navigateTo(to)
    }
  } else if (step.value == RegisterStep.Account) {
    if (await accountForm.value?.validate() ?? false) {
      navigateTo(to)
    }
  } else if (step.value == RegisterStep.ShippingAddress) {
    if (await shippingAddressForm.value?.validate() ?? false) {
      navigateTo(to)
    }
  }
}

const loadQueryParameters = () => {
  const planQueryParam = route.query["plan"]
  if (typeof planQueryParam === "string") {
    checkoutData.plan = planQueryParam
  }

  const couponQueryParam = route.query["coupon"]
  if (typeof couponQueryParam === "string") {
    couponCodes.value = [couponQueryParam]
  } else if (Array.isArray(couponQueryParam)) {
    couponCodes.value = couponQueryParam as string[]
  }

  const subscriptionGiftIdQueryParam = route.query["subscriptionGiftId"]
  if (typeof subscriptionGiftIdQueryParam === "string") {
    checkoutData.subscriptionGiftId = subscriptionGiftIdQueryParam
  }
}

var watchRouteParamHandle: WatchStopHandle
var watchStepHandle: WatchStopHandle
var watchExperimentsStopHandle: WatchStopHandle
const initializeWatches = () => {
  watchRouteParamHandle = watch(() => route.params, updateStep)
  watchStepHandle = watch(step, () => {
    if (step.value == RegisterStep.Checkout) {
      if (couponCodes.value) {
        setTimeout(() => {
          couponCodes.value?.forEach((couponCode) => {
            checkoutForm.value?.applyCoupon(couponCode, false)
          })
        }, 200)
      }
    }
  }, { immediate: true })
}

const stopWatches = () => {
  watchRouteParamHandle?.apply(this)
  watchStepHandle?.apply(this)
  watchExperimentsStopHandle?.apply(this)
}

const emitBeginCheckoutEvent = () => {
  try {
    const gtmDataLayer = gtm?.dataLayer()

    if (gtmDataLayer) {
      const planId = checkoutData.plan ? planAliasToId(checkoutData.plan, checkoutData.discountCoupon != null || forceMonthlyCharges.value) : null
      const planPrice = planId ? planBasePrice[planId] : null

      gtmDataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          "transaction_id": transactionId,
          "currency": "BRL",
          "value": planPrice ? planPrice / 100.0 : null,
          "coupon": couponCodes.value?.[0],
          "payment_method": 'CREDIT_CARD',
          "items": [{
            "item_id": planId,
            "item_name": planId,
            "price": planPrice ? planPrice / 100.0 : null,
            "quantity": 1
          }]
        }
      })
    }
  } catch (error) {
    captureException(new Error('Failed to track begin checkout gtm event', { cause: error }))
  }
}

const trackGtmEvent = (options: TrackEventOptions) => {
  try {
    gtm?.trackEvent(options)
  } catch (error) {
    captureException(new Error('Failed to track gtm event', { cause: error }))
  }
}

const emitPurchaseEvent = (subscription: Subscription) => {
  try {
    const gtmDataLayer = gtm?.dataLayer()

    if (gtmDataLayer) {
      const planId = checkoutData.plan ? planAliasToId(checkoutData.plan, checkoutData.discountCoupon != null || forceMonthlyCharges.value) : null
      const planPrice = planId ? planBasePrice[planId] : null

      gtmDataLayer.push({
        event: "purchase",
        ecommerce: {
          "transaction_id": transactionId,
          "currency": "BRL",
          "value": planPrice ? planPrice / 100.0 : null,
          "coupon": checkoutData.discountCoupon?.id ?? checkoutData.giftCoupon?.couponId,
          "shipping": subscription.shippingCost / 100.0,
          "payment_method": 'CREDIT_CARD',
          "items": [{
            "item_id": planId,
            "item_name": planId,
            "price": planPrice ? planPrice / 100.0 : null,
            "quantity": 1
          }]
        }
      })
    }
  } catch (error) {
    captureException(new Error('Failed to track purchase gtm event', { cause: error }))
  }
}

const updateStep = () => {
  const stepParam = route.params["step"]
  const currentQuestionParam = route.params["currentQuestion"]

  if (typeof stepParam === "string") {
    const currentStep = aliasToStep[stepParam]

    if (currentStep) {
      step.value = currentStep

      if (currentQuestionParam && typeof currentQuestionParam === "string") {
        const question = parseInt(currentQuestionParam, 10)

        if (question >= 1 && question <= (questionnaire.value?.questions?.length ?? 0)) {
          currentQuestion.value = question
        } else {
          router.replace({ name: route.name!, params: { step: stepToAlias[steps.value[0]], currentQuestion: "1" }, query: route.query })
        }
      }
    } else {
      if (steps.value[0] == RegisterStep.Profile) {
        router.replace({ name: route.name!, params: { step: stepToAlias[steps.value[0]], currentQuestion: "1" }, query: route.query })
      } else {
        router.replace({ name: route.name!, params: { step: stepToAlias[steps.value[0]] }, query: route.query })
      }
    }
  } else {
    if (steps.value[0] == RegisterStep.Profile) {
      router.replace({ name: route.name!, params: { step: stepToAlias[steps.value[0]], currentQuestion: "1" }, query: route.query })
    } else {
      router.replace({ name: route.name!, params: { step: stepToAlias[steps.value[0]] }, query: route.query })
    }
  }
}

const redirectToSignIn = () => {
  const profileSessionStorage = useSessionStorage<string>("register-profile", null)

  const profile = {
    questionnaireId: profileData.questionnaireId,
    answers: profileData.answers
  }

  profileSessionStorage.value = JSON.stringify(profile)

  const plan = checkoutData.plan ?? undefined

  router.push({
    name: "sign-in",
    query: {
      redirect: router.resolve({ name: "subscribe", query: { plan } }).fullPath,
      email: accountData.email.trim()
    }
  })
}

const signUp = async () => {
  const profileIsValid = numberOfProductTypesStillNeeded.value == 0
  const accountIsValid = await accountForm.value?.validate() ?? false

  if (!profileIsValid) {
    showProfileWarning.value = true

    router.push({ name: route.name!, params: { step: stepToAlias[RegisterStep.Profile], currentQuestion: firstProductTypeStep.value } })

    return
  } else if (!accountIsValid) {
    router.push({ name: route.name!, params: { step: stepToAlias[RegisterStep.Account] } })

    return
  }

  const facebookPixelAttribution = FacebookPixelService.getPixelAttribution()

  var fg = await fingerprint()
  var recaptchaToken = await recaptcha?.executeRecaptcha('signUpCustomer') ?? null

  isSigningUp.value = true
  try {
    const response = await PancakeService.signUpCustomer({
      user: {
        email: accountData.email.trim().toLowerCase(),
        password: accountData.password.trim()
      },
      customer: {
        firstName: accountData.firstName.trim(),
        lastName: accountData.lastName.trim(),
        email: accountData.email.trim().toLowerCase(),
        phoneNumber: "+55" + accountData.phoneNumber.replace(/\(/g, "").replace(/\)/g, "").replace(/\s/g, "").replace(/\-/g, ""),
        birthDate: localDateToISODate(accountData.birthdate),
        cpf: accountData.cpf,
        termsOfUseVersion: config.termsOfUseVersion,
        privacyPolicyVersion: config.privacyPolicyVersion,
        acceptsPromotionalEmails: accountData.acceptsPromotionalEmails,
      },
      profile: {
        questionnaireId: profileData.questionnaireId,
        answers: profileData.answers
      },
      mediaAttribution: null, // TODO
      fingerprint: fg,
      recaptchaToken: recaptchaToken
    })

    trackGtmEvent({
      category: "sign_up",
      action: "registered",
      label: "from_registration"
    })

    gtmPush({ event: "registered" })

    auth.storeAuthToken(response.token)

    customerId.value = response.customer.id

    router.push(next.value)
  } catch (error: FetchError | any) {
    if ('status' in error && error.status == 409) {
      showUsedEmailDialog.value = true

      gtmPush({ event: "registration-failed", reason: "email_already_registered" })
    } else {
      captureException(new Error('Failed to register a new account', { cause: error }))

      notifications.error("Houve um problema ao criar a sua conta. Por favor, tente novamente mais tarde")

      gtmPush({ event: "registration-failed", reason: "unknown" })
    }
  } finally {
    isSigningUp.value = false
  }
}

const createShippingAddress = async () => {
  const shippingAddressIsValid = await shippingAddressForm.value?.validate() ?? false

  if (!shippingAddressIsValid) {
    return
  }

  if (customerId.value != null) {
    isCreatingShippingAddress.value = true

    try {
      const response = await shippingAddressService.createSelfShippingAddress({
        street: shippingAddressInfo.street.trim(),
        complement: shippingAddressInfo.complement ? shippingAddressInfo.complement : undefined,
        number: shippingAddressInfo.number.trim(),
        district: shippingAddressInfo.district.trim(),
        city: shippingAddressInfo.city.trim(),
        state: shippingAddressInfo.state,
        postalCode: shippingAddressInfo.postalCode
      })

      shippingAddressId.value = response.id

      gtmPush({ event: "shipping-address-created" })

      router.push(next.value)
    } catch (error: FetchError | any) {
      captureException(new Error('Failed to register a new shipping address', { cause: error }))

      notifications.error("Houve um problema ao registar o seu endereço. Por favor, tente novamente mais tarde")

      gtmPush({ event: "shipping-address-failed", reason: "unknown" })
    } finally {
      isCreatingShippingAddress.value = false
    }
  }
}

const verifyCreditCard = async (creditCardToken: string): Promise<boolean> => {
  try {
    const verifyCreditCard = cardVerificationEnabled && posthog?.getFeatureFlag('verify-credit-card') == 'true'

    if (verifyCreditCard) {
      const verificationResponse = await VindiService.verifyCreditCard(billingPlatformId, { creditCardToken })

      if (!verificationResponse.successful) {
        trackGtmEvent({
          category: "credit_card_verification",
          action: "failed",
          label: "from_registration"
        })

        notifications.warning("Cartão de crédito negado. Por favor, verifique os dados do cartão de crédito e tente novamente", 10_000)

        gtmPush({ event: "credit-card-verification-failed", reason: "invalid_credit_card" })

        return false
      }
    }

    return true
  } catch (error: any) {
    captureException(new Error('Failed to verify credit card', { cause: error }))

    gtmPush({ event: "credit-card-verification-failed", reason: "unknown" })

    return false
  }
}

const subscribe = async () => {
  if (customerId.value == null || shippingAddressId.value == null) {
    await fullSubscribe()

    return
  }

  const checkoutIsValid = await checkoutForm.value?.validate() ?? false
  if (!checkoutIsValid) {
    return
  }

  isProcessingSubscription.value = true

  const planType = checkoutData.plan ?? 'monthly'
  const planId = planAliasToId(planType, checkoutData.discountCoupon != null || forceMonthlyCharges.value)
  const creditCard = checkoutData.creditCardInformation

  posthog?.capture('initiate-subscription')

  try {
    var vindiTokenizationResponse: VindiTokenizationResponse | null = null
    try {
      vindiTokenizationResponse = checkoutForm ?
        await VindiService.tokenizeCreditCard({
          holderName: creditCard.cardHolderName.trim(),
          cardNumber: creditCard.cardNumber,
          cardExpiration: creditCard.cardExpiration,
          cardCVV: creditCard.cardVerificationCode,
          paymentCompanyCode: creditCard.cardIssuer
        }) : null

      const creditCardToken = vindiTokenizationResponse?.gatewayToken ?? null
      if (creditCardToken && !await verifyCreditCard(creditCardToken)) {
        return
      }

      trackGtmEvent({
        category: "credit_card_verification",
        action: "successful",
        label: "from_registration"
      })

      gtmPush({ event: "credit-card-verified" })
    } catch (error: FetchError | any) {
      trackGtmEvent({
        category: "credit_card_verification",
        action: "failed",
        label: "from_registration"
      })

      if ('status' in error && error.status == 422) {
        gtmPush({ event: "credit-card-verification-failed", reason: "invalid_credit_card" })

        notifications.warning("Cartão de crédito inválido. Por favor, verifique os dados do cartão de crédito e tente novamente", 10_000)

        return
      } else {
        captureException(new Error('Failed to verify credit card', { cause: error }))

        gtmPush({ event: "credit-card-verification-failed", reason: "unknown" })

        notifications.error("Falha ao verificar cartão de crédito. Por favor, verifique os dados do cartão e tente novamente", 10_000)

        return
      }
    }

    const facebookPixelAttribution = FacebookPixelService.getPixelAttribution()

    var fg = await fingerprint()
    var recaptchaToken = await recaptcha?.executeRecaptcha('subscribe') ?? null

    const response = await PancakeService.subscribe({
      billingPlatformId: billingPlatformId,
      customerId: customerId.value,
      shippingAddressId: shippingAddressId.value,
      paymentProfile: {
        creditCardToken: vindiTokenizationResponse?.gatewayToken ?? null
      },
      subscription: { planId: planId, couponId: checkoutData.discountCoupon?.id ?? null, giftCoupon: checkoutData.giftCoupon ?? null },
      experiments: checkoutData.experiments,
      promotions: checkoutData.promotions,
      mediaAttribution: null, // TODO
      facebookAttribution: {
        transactionId: transactionId,
        eventSourceUrl: "https://boxmagenta.com.br/assinar",
        fbp: facebookPixelAttribution.fbp,
        fbc: facebookPixelAttribution.fbc
      },
      fingerprint: fg,
      recaptchaToken: recaptchaToken
    })

    emitPurchaseEvent(response.subscription)

    trackGtmEvent({
      category: "subscription",
      action: "created",
      label: "from_registration"
    })

    gtmPush({ event: "subscribed", planId, planType, converted: response.subscription.status == "ACTIVE" })

    if (response.subscription.status == "ACTIVE") {
      const planId = checkoutData.plan ? planAliasToId(checkoutData.plan, checkoutData.discountCoupon != null) : null
      const planPrice = planId ? planBasePrice[planId] : null
      const discount = checkoutData.discountCoupon?.amount ?? (checkoutData.discountCoupon?.percentage && planPrice ? planPrice * checkoutData.discountCoupon?.percentage / 100.0 : 0)

      const amount = planPrice ? ((planPrice - discount) / 100.0) : 0.0

      if (amount > 0) {
        trackSubscriptionConversion({
          subscriptionId: response.subscription.id,
          amount: amount,
          couponCode: checkoutData.discountCoupon?.id
        })
      }
    }

    notifications.success("Assinatura realizada com sucesso!")

    activeSubscription.refresh()

    const isLastStep = steps.value.indexOf(step.value) == steps.value.length - 1
    if (isLastStep) {
      router.push({ name: "dashboard" })
    } else {
      router.push(next.value)
    }
  } catch (error: FetchError | any) {
    if ('status' in error) {
      if (error.status == 403) {
        captureException(new Error("Forbidden to register a new subscription", { cause: error }))

        gtmPush({ event: "subscription-failed", planId, planType, reason: "forbidden" })
      } else if (error.status == 429) {
        captureException(new Error("New subscription registration rate limited", { cause: error }))

        gtmPush({ event: "subscription-failed", planId, planType, reason: "rate_limited" })
      } else {
        captureException(new Error('Failed to register a new subscription', { cause: error }))

        gtmPush({ event: "subscription-failed", planId, planType, reason: "unknown" })
      }

      notifications.error("Houve um problema ao processar sua assinatura. Por favor, tente novamente mais tarde")
    } else {
      captureException(new Error('Failed to register a new subscription', { cause: error }))

      gtmPush({ event: "subscription-failed", planId, planType, reason: "unknown" })

      notifications.error("Houve um problema ao processar sua assinatura. Por favor, tente novamente mais tarde")
    }
  } finally {
    isProcessingSubscription.value = false
  }
}

const fullSubscribe = async () => {
  const profileIsValid = numberOfProductTypesStillNeeded.value == 0
  const accountIsValid = await accountForm.value?.validate() ?? false
  const shippingAddressIsValid = await shippingAddressForm.value?.validate() ?? false
  const checkoutIsValid = await checkoutForm.value?.validate() ?? false

  if (!profileIsValid) {
    showProfileWarning.value = true

    router.push({ name: route.name!, params: { step: stepToAlias[RegisterStep.Profile], currentQuestion: firstProductTypeStep.value } })
  } else if (!accountIsValid) {
    router.push({ name: route.name!, params: { step: stepToAlias[RegisterStep.Account] } })
  } else if (!shippingAddressIsValid) {
    router.push({ name: route.name!, params: { step: stepToAlias[RegisterStep.ShippingAddress] } })
  } else if (profileIsValid && accountIsValid && shippingAddressIsValid && checkoutIsValid) {
    isProcessingSubscription.value = true


    const planType = checkoutData.plan ?? 'monthly'
    const planId = planAliasToId(planType, checkoutData.discountCoupon?.id != null || forceMonthlyCharges.value)

    posthog?.capture('initiate-subscription')

    try {
      const creditCard = checkoutData.creditCardInformation

      var vindiTokenizationResponse: VindiTokenizationResponse | null = null
      try {
        vindiTokenizationResponse = checkoutForm ?
          await VindiService.tokenizeCreditCard({
            holderName: creditCard.cardHolderName.trim(),
            cardNumber: creditCard.cardNumber,
            cardExpiration: creditCard.cardExpiration,
            cardCVV: creditCard.cardVerificationCode,
            paymentCompanyCode: creditCard.cardIssuer
          }) : null

        const creditCardToken = vindiTokenizationResponse?.gatewayToken ?? null
        if (creditCardToken && !await verifyCreditCard(creditCardToken)) {
          return
        }

        trackGtmEvent({
          category: "credit_card_verification",
          action: "successful",
          label: "from_registration"
        })

        gtmPush({ event: "credit-card-verified" })
      } catch (error: FetchError | any) {
        trackGtmEvent({
          category: "credit_card_verification",
          action: "failed",
          label: "from_registration"
        })

        if ('status' in error && error.status == 422) {
          notifications.warning("Cartão de crédito inválido. Por favor, verifique os dados do cartão de crédito e tente novamente", 10_000)

          gtmPush({ event: "credit-card-verification-failed", reason: "invalid_credit_card" })

          return
        } else {
          captureException(new Error('Failed to verify credit card', { cause: error }))

          gtmPush({ event: "credit-card-verification-failed", reason: "unknown" })

          notifications.error("Falha ao verificar cartão de crédito. Por favor, verifique os dados do cartão e tente novamente", 10_000)

          return
        }
      }

      const facebookPixelAttribution = FacebookPixelService.getPixelAttribution()

      var fg = await fingerprint()
      var recaptchaToken = await recaptcha?.executeRecaptcha('signUp') ?? null

      const response = await PancakeService.signUp({
        billingPlatformId: billingPlatformId,
        user: {
          email: accountData.email.trim().toLowerCase(),
          password: accountData.password.trim()
        },
        customer: {
          firstName: accountData.firstName.trim(),
          lastName: accountData.lastName.trim(),
          email: accountData.email.trim().toLowerCase(),
          phoneNumber: "+55" + accountData.phoneNumber.replace(/\(/g, "").replace(/\)/g, "").replace(/\s/g, "").replace(/\-/g, ""),
          birthDate: localDateToISODate(accountData.birthdate),
          cpf: accountData.cpf,
          termsOfUseVersion: config.termsOfUseVersion,
          privacyPolicyVersion: config.privacyPolicyVersion,
          acceptsPromotionalEmails: accountData.acceptsPromotionalEmails,
        },
        profile: {
          questionnaireId: profileData.questionnaireId,
          answers: profileData.answers
        },
        shippingAddress: {
          street: shippingAddressInfo.street.trim(),
          complement: shippingAddressInfo.complement ? shippingAddressInfo.complement : null,
          number: shippingAddressInfo.number.trim(),
          district: shippingAddressInfo.district.trim(),
          city: shippingAddressInfo.city.trim(),
          state: shippingAddressInfo.state,
          postalCode: shippingAddressInfo.postalCode
        },
        paymentProfile: {
          creditCardToken: vindiTokenizationResponse?.gatewayToken ?? null
        },
        subscription: { planId: planId, couponId: checkoutData.discountCoupon?.id ?? null, giftCoupon: checkoutData.giftCoupon ?? null },
        promotions: checkoutData.promotions,
        mediaAttribution: null, // TODO
        facebookAttribution: {
          transactionId: transactionId,
          eventSourceUrl: "https://boxmagenta.com.br/assinar",
          fbp: facebookPixelAttribution.fbp,
          fbc: facebookPixelAttribution.fbc
        },
        fingerprint: fg,
        recaptchaToken: recaptchaToken
      })

      if (response.subscription) {
        emitPurchaseEvent(response.subscription)

        trackGtmEvent({
          category: "subscription",
          action: "created",
          label: "from_registration"
        })

        gtmPush({ event: "subscribed", planId, planType, converted: response.subscription.status == "ACTIVE" })
      } else {
        trackGtmEvent({
          category: "subscription",
          action: "not_created",
          label: "from_registration"
        })

        gtmPush({ event: "subscription-failed", planId, planType, reason: "unknown" })
      }

      activeSubscription.refresh()

      notifications.success("Assinatura realizada com sucesso!")

      auth.storeAuthToken(response.token)

      const isLastStep = steps.value.indexOf(step.value) == steps.value.length - 1
      if (isLastStep) {
        router.push({ name: "dashboard" })
      } else {
        router.push(next.value)
      }
    } catch (error: FetchError | any) {
      if ('status' in error) {
        if (error.status == 409) {
          notifications.error("Já existe uma conta com o e-mail informado. Por favor, acesse a sua conta para finalizar a sua assinatura")

          gtmPush({ event: "registration-failed", reason: "email_already_registered" })
        } else {
          if ("status" in error && error.status == 403) {
            captureException(new Error("Forbidden to register a new subscription", { cause: error }))

            gtmPush({ event: "subscription-failed", planId, planType, reason: "forbidden" })
          } else if ("status" in error && error.status == 429) {
            captureException(new Error("New subscription registration rate limited", { cause: error }))

            gtmPush({ event: "subscription-failed", planId, planType, reason: "rate_limited" })
          } else {
            captureException(new Error('Failed to register a new subscription', { cause: error }))

            gtmPush({ event: "subscription-failed", planId, planType, reason: "unknown" })
          }

          notifications.error("Houve um problema ao processar sua assinatura. Por favor, tente novamente mais tarde")
        }
      } else {
        captureException(new Error('Failed to register a new subscription', { cause: error }))

        notifications.error("Houve um problema ao processar sua assinatura. Por favor, tente novamente mais tarde")

        gtmPush({ event: "subscription-failed", planId, planType, reason: "unknown" })
      }
    } finally {
      isProcessingSubscription.value = false
    }
  }
}

const saveProfile = async () => {
  if ((numberOfProductTypesStillNeeded.value ?? 0) > 0) {
    showProfileWarning.value = true

    return
  }

  isSavingProfile.value = true

  try {
    const questionnaireId = questionnaire.value?.id

    if (questionnaireId) {
      await ProfileService.updateSelfProfile({
        questionnaireId,
        answers: profileData.answers
      })

      notifications.error("Perfil de beleza salvo com sucesso")

      router.push({ name: "dashboard" })
    }
  } catch (error) {
    captureException(new Error('Failed to save profile', { cause: error }))

    notifications.error("Não foi possível salvar os dados do seu perfil de beleza")
  } finally {
    isSavingProfile.value = false
  }
}

onMounted(async () => {
  try {
    await Promise.all([QuestionnaireService.fetchLatest()])
      .then(([questionnaireResponse]) => {
        questionnaire.value = questionnaireResponse
        profileData.answers = ProfileService.cleanUpProfile([], questionnaireResponse)
      })

    loadQueryParameters()
    emitBeginCheckoutEvent()

    initializeWatches()
    updateStep()

    loading.value = false
  } catch (error) {
    captureException(new Error('Failed to load questionnaire', { cause: error }))

    notifications.error("Não foi possível carregar os dados de assinatura. Por favor, tente novamente mais tarde")

    router.replace({ name: "home" })
  }
})

onUnmounted(() => {
  stopWatches()
})
</script>
<style lang="sass" scoped>
.register
  position: relative
  width: 100%
  max-width: 1200px
  margin: 0 auto
  padding: 0 14px
  text-align: left
  animation: fade-in .2s forwards

  .page
    &.slim
      max-width: 680px
      margin: 0 auto

  .actions
    position: relative
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    margin-top: 32px
    height: 64px
    max-width: 640px
    margin: 0 auto

    .loading
      height: 50px

    .back
      position: absolute
      left: 0

    .next
      position: absolute
      right: 0

    .large
      text-align: center
      font-size: 24px
      width: 220px
      height: 48px
</style>
